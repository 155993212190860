import React, { FC, useState } from 'react'
import * as pageUtils from './__details.utils'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { CheckboxList, RadioList, SelectList } from '@utils/request'
import { formatStrapiText } from '@utils/methods'
import TableDetailsModal from '../modals/tableDetailsModal'
import Text from '@components/ui/text'
import Collapse from '@components/ui/collapse'
import Modal from '@components/ui/modal'
import * as formUtils from '@components/requestForms/__forms.utils'
import parse from 'html-react-parser'
import { formatHtmlForStrapiText } from '@utils/methods'
import { joinClasses } from '@utils/styles'
import { formatNumberWithSpaces } from '@components/requestForms/__forms.utils'

const BuildingConstructionOrAdditionPermitFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const radioListRequestAs: RadioList[] = [
		{
			label: pageAssets?.request_form_individual,
			value: REQUEST_CONFIGS_KEYS.requestAs.individual,
			key: 'request_form_individual'
		},
		{
			label: pageAssets?.request_form_entreprise,
			value: REQUEST_CONFIGS_KEYS.requestAs.entreprise,
			key: 'request_form_entreprise'
		}, //Entrepreneur or manager
		{
			label: pageAssets?.request_form_entrepreneur_or_manager,
			value: REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager,
			key: 'request_form_entrepreneur_or_manager'
		}
	]

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const radioListWhatToDo: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_request_installation,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoBuildingConstructionOrAdditionPermit
					.request_new_construction,
			key: 'request_form_buildingConstructionOrAdditionPermit_request_installation',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_request_installation_extra_text
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_request_upgrading,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoBuildingConstructionOrAdditionPermit
					.request_addition,
			key: 'request_form_buildingConstructionOrAdditionPermit_request_upgrading',
			extraText:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_request_addition_extra_text
		}
	]

	const radioListMainUseOfNewBuilding: RadioList[] =
		formUtils.radioListMainUseOfNewBuilding(pageAssets)

	const radioListIsTheNewBuilding: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option1,
			value: REQUEST_CONFIGS_KEYS.isTheNewBuilding.firstOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option1'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option2,
			value: REQUEST_CONFIGS_KEYS.isTheNewBuilding.secondOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option2'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option3,
			value: REQUEST_CONFIGS_KEYS.isTheNewBuilding.thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option3'
		}
	]

	const radioListMainUseOfExistingBuilding: RadioList[] =
		formUtils.radioListMainUseOfExistingBuilding(pageAssets)

	const radioListStructureType: RadioList[] =
		formUtils.radioListStructureType(pageAssets)

	// shoud display questions
	const shouldDisplayQuestions = (usageType: string | undefined) => {
		switch (usageType?.toString()) {
			case REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.firstOption:
			case REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.secondOption:
			case REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.thirdOption:
				return {
					Q14: true,
					Q15: true,
					Q16_1: false,
					Q17: true,
					Q18: true
				}

			case REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.fourthOption:
				return {
					Q14: true,
					Q15: true,
					Q16_1: true,
					Q17: true,
					Q18: true
				}

			case REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.fifthOption:
			case REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.sixthOption:
			case REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.eighthOption:
				return {
					Q14: true,
					Q15: true,
					Q17: true,
					Q16_1: false,
					Q18: false
				}

			case REQUEST_CONFIGS_KEYS.mainUseOfNewBuilding.seventhOption:
				return {
					Q14: true,
					Q15: false,
					Q17: false,
					Q16_1: false,
					Q18: false
				}

			default:
				return {
					Q14: false,
					Q15: false,
					Q17: false,
					Q16_1: false,
					Q18: false
				}
		}
	}

	// Measurement unit m² or ft²
	const radioListMeasurement2Unit: RadioList[] = [
		{
			label: pageAssets?.request_form_meters2,
			value: REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			key: 'request_form_meters2'
		},
		{
			label: pageAssets?.request_form_foot2,
			value: REQUEST_CONFIGS_KEYS.measurement_unit.foot_unit,
			key: 'request_form_foot2'
		}
	]

	// Garage location
	const radioListGarageLocation: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_garageLocation_firstOption,
			value: REQUEST_CONFIGS_KEYS.garageLocation.firstOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_garageLocation_firstOption'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_garageLocation_secondOption,
			value: REQUEST_CONFIGS_KEYS.garageLocation.secondOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_garageLocation_secondOption'
		}
	]

	const radioListWhatToBuildOrImprove: RadioList[] =
		formUtils.radioListWhatToBuildOrImprove(pageAssets)

	// shoud display questions
	const isPAQuestionsRequired = (whatToBuildOrImprove: string | undefined) => {
		switch (whatToBuildOrImprove?.toString()) {
			case REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.firstOption:
			case REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.secondOption:
				return {
					Q13: true,
					Q14: false,
					Q15: true,
					Q16: true,
					Q17: true,
					Q18: true,
					Q19: true,
					Q20: true,
					Q21: true,
					Q22: true,
					Q23: true
				}

			case REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.thirdOption:
				return {
					Q13: false,
					Q14: true,
					Q15: true,
					Q16: true,
					Q17: true,
					Q18: true,
					Q19: true,
					Q20: true,
					Q21: true,
					Q22: true,
					Q23: true
				}

			case REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fourthOption:
				return {
					Q13: false,
					Q14: false,
					Q15: false,
					Q16: true,
					Q17: true,
					Q18: true,
					Q19: true,
					Q20: true,
					Q21: false,
					Q22: false,
					Q23: false
				}
			// case REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fifthOption:
			case REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.sixthOption:
				return {
					Q13: false,
					Q14: false,
					Q15: false,
					Q16: true,
					Q17: true,
					Q18: true,
					Q19: true,
					Q20: true,
					Q21: true,
					Q22: true,
					Q23: true
				}
			// case REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.seventhOption:
		}
	}

	const shouldDisplayPAQuestion = (
		value: string | undefined,
		question: string
	) => {
		if (
			!value ||
			value == REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fifthOption
		) {
			return false
		}

		if (value == REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.seventhOption) {
			return question !== 'Q13'
		}

		return isPAQuestionsRequired(value)?.[question]
	}

	const radioListConstructionType: SelectList[] =
		formUtils.radioListConstructionType(pageAssets)

	const radioListFoundationType: SelectList[] =
		formUtils.radioListFoundationType(pageAssets)

	const radioListPlaceOnTheTerrain: SelectList[] =
		formUtils.radioListPlaceOnTheTerrain(pageAssets)

	// Measurement unit m or ft
	const radioListMeasurementUnit: RadioList[] = [
		{
			label: pageAssets?.request_form_meters,
			value: REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			key: 'request_form_meters'
		},
		{
			label: pageAssets?.request_form_foot,
			value: REQUEST_CONFIGS_KEYS.measurement_unit.foot_unit,
			key: 'request_form_foot'
		}
	]

	const radioListWhoWillBeInChargeOfWork: RadioList[] =
		formUtils.radioListWhoWillBeInChargeOfWork(pageAssets)

	const radioListIsPropertyConnectedToMunicipalConduits: CheckboxList[] =
		formUtils.radioListIsPropertyConnectedToMunicipalConduits(pageAssets)

	const listPlannedWork: CheckboxList[] = formUtils.listPlannedWork(pageAssets)

	const questionAnswer = (question: string, answer?: string) => {
		if (answer != undefined) {
			return (
				<>
					<div className={pageUtils.classes.answerMargin}>
						<strong>{question}</strong>
						<br />
						<span>{answer}</span>
					</div>
				</>
			)
		}
	}

	const getLabelFromList = (list: SelectList[], value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	const getLabelsFromString = (list, answer?: string): string[] => {
		return list
			.filter((element) => answer?.includes(element.value))
			.map((item) => item.label)
	}

	const isEntrepreneurFieldsRequired = (value) => {
		return [
			listPlannedWork[0].value,
			listPlannedWork[1].value,
			listPlannedWork[2].value,
			listPlannedWork[5].value,
			listPlannedWork[6].value
		].some((option) => value?.toString()?.includes(option))
	}

	const { incidentForm } = request

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

	const isNewConstruction = () =>
		incidentForm?.whatToDo?.toString() ==
		REQUEST_CONFIGS_KEYS.whatToDoBuildingConstructionOrAdditionPermit
			.request_new_construction

	const isAddition = () =>
		incidentForm?.whatToDo?.toString() ==
		REQUEST_CONFIGS_KEYS.whatToDoBuildingConstructionOrAdditionPermit
			.request_addition

	const isAucunChoix = () => {
		if (incidentForm?.isTheNewBuilding?.toString() == radioListIsTheNewBuilding[2].value) {
			return `(${radioListIsTheNewBuilding[0].label}, ${radioListIsTheNewBuilding[1].label})`
		}
		return ''
	}

	return (
		<>
			{/* Question 1 */}
			<div>
				<strong>{pageAssets.request_form_what_would_you_like_to_do}</strong>
				<br />
				<span>
					{getLabelFromList(radioListWhatToDo, incidentForm?.whatToDo)?.replace(
						/\*\*/g,
						''
					)}
				</span>
			</div>

			<p style={{ marginBottom: '20px' }}>
				<span
					onClick={() => {
						setIsModalOpen(true)
					}}
					className={pageUtils.classes.viewDetailsSpan}
				>
					<b>{pageAssets.request_form_show_details}</b>
				</span>
			</p>

			<Modal
				// cancelButtonText={pageAssets?.close_label}
				primaryButtonText={pageAssets?.close_label}
				isOpen={isModalOpen}
				onCancelBtn={() => {
					setIsModalOpen(false)
				}}
				onPrimaryButton={() => {
					setIsModalOpen(false)
				}}
				onSetIsOpen={() => {}}
				alignBtn={'left'}
				title={pageAssets?.request_form_request_details}
				showExitBtn
			>
				<div>
					<div
						style={{ marginTop: '-20px' }}
						className={joinClasses([
							pageUtils.classes.collapse,
							pageUtils.classes.summarySubTitles
						])}
					>
						<>
							<Collapse
								id={1}
								buttonText={pageAssets?.request_form_type_de_demandes}
								key={`request_form_type_de_demandes`}
								isOpen={false}
							>
								{/* Question 1 */}
								{questionAnswer(
									pageAssets?.request_form_what_would_you_like_to_do,
									getLabelFromList(
										radioListWhatToDo,
										incidentForm?.whatToDo
									)?.replace(/\*\*/g, '')
								)}

								{/* Question 2 */}
								{questionAnswer(
									pageAssets?.request_form_request_as,
									getLabelFromList(radioListRequestAs, incidentForm?.requestAs)
								)}

								{incidentForm?.requestAs?.toString() ==
									REQUEST_CONFIGS_KEYS.requestAs.entreprise && (
									<>
										{questionAnswer(
											pageAssets?.request_form_annualRegistrationCertificateForContractors_companyName_label,
											incidentForm?.companyName
										)}
										{questionAnswer(
											pageAssets?.request_form_swimmingPoolSpaPermit_representativeName_label,
											incidentForm?.representativeName
										)}
									</>
								)}

								{/* Question 3: Is Subject to PIIA Approval */}
								{questionAnswer(
									pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_label,
									getLabelFromList(
										radioListYesNo,
										incidentForm?.isSubjectToPiiaApproval
									)
								)}

								{/* Question 4: Has Been Subject to Approval */}
								{incidentForm?.hasBeenSubjectToApproval?.toString() ==
									radioListYesNo[0].value && (
									<>
										{questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_hasBeenSubjectToApproval_label,
											getLabelFromList(
												radioListYesNo,
												incidentForm?.hasBeenSubjectToApproval
											)
										)}
										{incidentForm?.hasBeenSubjectToApproval?.toString() ==
											radioListYesNo[0].value && (
											<>
												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_iaNumber_label,
													incidentForm?.iaNumber
												)}

												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_ecResolutionNumber_label,
													incidentForm?.ecResolutionNumber
												)}
											</>
										)}
									</>
								)}

								{/* Question 5: Is Real Estate Project */}
								{questionAnswer(
									pageAssets?.request_form_buildingConstructionOrAdditionPermit_isRealEstateProject_label,
									getLabelFromList(
										radioListYesNo,
										incidentForm?.isRealEstateProject
									)
								)}

								{/* Question 6: Project Number */}
								{incidentForm?.isRealEstateProject?.toString() ==
									radioListYesNo[0].value &&
									questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_projectNumber_label,
										incidentForm?.projectNumber
									)}
								<br />
							</Collapse>

							<Collapse
								id={2}
								buttonText={pageAssets?.request_form_request_location}
								key={`request_form_request_location`}
								isOpen={false}
							>
								{isAddition() &&
									questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSameLocation,
										getLabelFromList(
											radioListYesNo,
											incidentForm?.buildingAddressSameHasApplicant
										)
									)}

								{(incidentForm?.buildingAddressSameHasApplicant?.toString() ==
									radioListYesNo[1].value ||
									isNewConstruction()) && (
									<>
										{questionAnswer(
											pageAssets?.request_form_location,
											incidentForm?.addressField
										)}
										{questionAnswer(
											pageAssets?.label_cadastral_number,
											incidentForm?.addressLotNumber?.toString()
										)}

										{incidentForm?.addressVoiePublic && (
											<div>
												<strong>
													{
														pageAssets?.request_form_addressVoiePublicSection_public_road
													}
												</strong>
												<br />
												<span>
													{incidentForm?.addressCivicNumber
														? incidentForm?.addressCivicNumber + ', '
														: ''}
													{incidentForm?.addressVoiePublic}
												</span>
											</div>
										)}
										{incidentForm?.addressVoiePublic2 && (
											<div>
												<span>
													{incidentForm?.addressCivicNumber2
														? incidentForm?.addressCivicNumber2 + ', '
														: ''}
													{incidentForm?.addressVoiePublic2}
												</span>
											</div>
										)}

										{incidentForm?.addressVoiePublic3 && (
											<div>
												<span>
													{incidentForm?.addressCivicNumber3
														? incidentForm?.addressCivicNumber3 + ', '
														: ''}
													{incidentForm?.addressVoiePublic3}
												</span>
											</div>
										)}

										{incidentForm?.addressAdditionalInfos && (
											<div>
												<span>{incidentForm?.addressAdditionalInfos}</span>
											</div>
										)}
										<div className={pageUtils.classes.answerMargin}></div>
									</>
								)}

								{/* Question 9 */}
								{questionAnswer(
									pageAssets?.request_form_swimmingPoolSpaPermit_isLocationOwned,
									getLabelFromList(
										radioListYesNo,
										incidentForm?.isLocationOwned
									)
								)}

								{/* ****** Owner inputs ****** */}
								{incidentForm?.isLocationOwned?.toString() ==
									radioListYesNo[1].value && (
									<>
										{questionAnswer(
											pageAssets?.label_firstName,
											incidentForm?.buildingOwnerInfo?.first_name
										)}
										{questionAnswer(
											pageAssets?.label_lastName,
											incidentForm?.buildingOwnerInfo?.last_name
										)}
										{questionAnswer(
											pageAssets?.label_email,
											incidentForm?.buildingOwnerInfo?.email
										)}
										{questionAnswer(
											pageAssets?.label_telephone,
											incidentForm?.buildingOwnerInfo?.telephone
										)}
									</>
								)}

								<br />
							</Collapse>

							<Collapse
								id={3}
								buttonText={pageAssets?.request_form_type_of_use}
								key={`request_form_type_of_use`}
								isOpen={false}
							>
								{isNewConstruction() && (
									<>
										{/* Question 10 */}
										{questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfNewBuilding,
											getLabelFromList(
												radioListMainUseOfNewBuilding,
												incidentForm?.mainUseOfBuilding
											)
										)}
										{/* Question 11 */}
										{questionAnswer(
											`${
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding
											} ${isAucunChoix()}`,
											getLabelFromList(
												radioListIsTheNewBuilding,
												incidentForm?.isTheNewBuilding
											)
										)}
										{/* Question 12 */}
										{questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuildingPartOfAProperty,
											getLabelFromList(
												radioListYesNo,
												incidentForm?.isTheNewBuildingPartOfAProperty
											)
										)}
									</>
								)}

								{isAddition() && (
									<>
										{/* Question 10 PA */}
										{questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding,
											getLabelFromList(
												radioListMainUseOfExistingBuilding,
												incidentForm?.mainUseOfExistingBuilding
											)
										)}

										{/* Question 10.1 PA */}
										{incidentForm?.mainUseOfExistingBuilding?.toString() ==
											REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding
												.secondOption &&
											questionAnswer(
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_existingMainBuildingHasHousing,
												getLabelFromList(
													radioListYesNo,
													incidentForm?.existingMainBuildingHasHousing
												)
											)}

										{/* Question 11 PA */}
										{questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuildingPartOfAProperty,
											getLabelFromList(
												radioListYesNo,
												incidentForm?.existingMainBuildingIsShared
											)
										)}
									</>
								)}

								<br />
							</Collapse>

							<Collapse
								id={4}
								buttonText={pageAssets?.request_form_building_informations}
								key={`request_form_building_informations`}
								isOpen={false}
							>
								{isNewConstruction() && (
									<>
										{/* Question 13 */}
										{questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_structureType,
											getLabelFromList(
												radioListStructureType,
												incidentForm?.structureType
											)
										)}

										<h5 className={pageUtils.classes.h5}>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_caracteristics
											}
										</h5>

										{/* Question 14 */}
										{shouldDisplayQuestions(incidentForm?.mainUseOfBuilding)
											?.Q14 && (
											<>
												{incidentForm?.buildingArea &&
													questionAnswer(
														pageAssets?.request_form_buildingConstructionOrAdditionPermit_buildingArea,
														`${formatNumberWithSpaces(
															incidentForm?.buildingArea?.toString()
														)} ${getLabelFromList(
															radioListMeasurement2Unit,
															incidentForm?.buildingAreaUnit
														)}`
													)}
											</>
										)}
										{/* Question 15 */}
										{shouldDisplayQuestions(incidentForm?.mainUseOfBuilding)
											?.Q15 && (
											<>
												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_totalGrossFloorArea,
													`${formatNumberWithSpaces(
														incidentForm?.totalGrossFloorArea?.toString()
													)} ${getLabelFromList(
														radioListMeasurement2Unit,
														incidentForm?.totalGrossFloorAreaUnit
													)}`
												)}
											</>
										)}

										{/* Question 16 */}
										{shouldDisplayQuestions(incidentForm?.mainUseOfBuilding)
											?.Q16_1 && (
											<>
												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_isDeclaredAreaForResidentialUse,
													getLabelFromList(
														radioListYesNo,
														incidentForm?.isDeclaredAreaForResidentialUse
													)
												)}
												{/* Question 16.2 */}
												{incidentForm?.isDeclaredAreaForResidentialUse?.toString() ==
													radioListYesNo[1].value && (
													<>
														{questionAnswer(
															pageAssets?.request_form_buildingConstructionOrAdditionPermit_nonResidentialAreas,
															`${
																incidentForm?.nonResidentialAreas
															} ${getLabelFromList(
																radioListMeasurement2Unit,
																incidentForm?.nonResidentialAreasUnit
															)}`
														)}
													</>
												)}
											</>
										)}
										{/* Question 17 */}
										{shouldDisplayQuestions(incidentForm?.mainUseOfBuilding)
											?.Q17 && (
											<>
												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_numberOfFloors,
													incidentForm?.numberOfFloors?.toString()
												)}
											</>
										)}
										{/* Question 18 */}
										{shouldDisplayQuestions(incidentForm?.mainUseOfBuilding)
											?.Q18 && (
											<>
												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_numberOfResidentialUnits,
													incidentForm?.numberOfResidentialUnits?.toString()
												)}
											</>
										)}

										{/* Garage et stationnement */}
										<h5 className={pageUtils.classes.h5}>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_garage_parking
											}
										</h5>

										{/* Question 19 */}
										{questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_hasGarage,
											getLabelFromList(radioListYesNo, incidentForm?.hasGarage)
										)}

										{/* Question 19.1 */}
										{incidentForm?.hasGarage?.toString() ==
											radioListYesNo[0].value && (
											<>
												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_garageLocation,
													getLabelFromList(
														radioListGarageLocation,
														incidentForm?.garageLocation
													)
												)}
											</>
										)}

										{/* Question 20 */}
										{incidentForm?.nbrOfParkingSpacesExt != null &&
											incidentForm?.nbrOfParkingSpacesExt?.toString() != '' &&
											Number(incidentForm?.nbrOfParkingSpacesExt) >= 0 && (
												<div className={pageUtils.classes.answerMargin}>
													<strong>
														{parse(
															formatHtmlForStrapiText(
																pageAssets?.request_form_buildingConstructionOrAdditionPermit_numberOfParkingSpacesExt
															)
														)}
													</strong>
													<span>{incidentForm?.nbrOfParkingSpacesExt}</span>
												</div>
											)}

										{/* Question 21 */}
										{incidentForm?.nbrOfParkingSpacesInt != null &&
											incidentForm?.nbrOfParkingSpacesInt?.toString() != '' &&
											Number(incidentForm?.nbrOfParkingSpacesInt) >= 0 && (
												<div className={pageUtils.classes.answerMargin}>
													<strong>
														{parse(
															formatHtmlForStrapiText(
																pageAssets?.request_form_buildingConstructionOrAdditionPermit_numberOfParkingSpacesInt
															)
														)}
													</strong>
													<span>{incidentForm?.nbrOfParkingSpacesInt}</span>
												</div>
											)}

										{/* Question 22 */}
										{incidentForm?.nbrOfParkingSpacesVis != null &&
											incidentForm?.nbrOfParkingSpacesVis?.toString() != '' &&
											Number(incidentForm?.nbrOfParkingSpacesVis) >= 0 && (
												<div className={pageUtils.classes.answerMargin}>
													<strong>
														{parse(
															formatHtmlForStrapiText(
																pageAssets?.request_form_buildingConstructionOrAdditionPermit_numberOfParkingSpacesVis
															)
														)}
													</strong>
													<span>{incidentForm?.nbrOfParkingSpacesVis}</span>
												</div>
											)}

										{/* Question 23 */}
										{incidentForm?.nbrOfBikeSpaces != null &&
											incidentForm?.nbrOfBikeSpaces?.toString() != '' &&
											Number(incidentForm?.nbrOfBikeSpaces) >= 0 && (
												<div className={pageUtils.classes.answerMargin}>
													<strong>
														{parse(
															formatHtmlForStrapiText(
																pageAssets?.request_form_buildingConstructionOrAdditionPermit_numberOfBikeSpaces
															)
														)}
													</strong>
													<span>{incidentForm?.nbrOfBikeSpaces}</span>
												</div>
											)}
									</>
								)}

								{isAddition() && (
									<>
										{/* Question 12 PA */}
										{questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_whatToBuildOrImprove,
											getLabelFromList(
												radioListWhatToBuildOrImprove,
												incidentForm?.whatToBuildOrImprove
											)?.replace(/\*\*/g, '')
										)}

										{incidentForm?.whatToBuildOrImprove &&
											incidentForm?.whatToBuildOrImprove?.toString() !=
												REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fifthOption &&
											(incidentForm?.constructionType ||
												incidentForm?.constructionTypeOther ||
												incidentForm?.foundationType ||
												incidentForm?.placeOnTheTerrain) && (
												<h5 className={pageUtils.classes.h5}>
													{radioListWhatToBuildOrImprove
														.find(
															(option) =>
																option.value ==
																incidentForm?.whatToBuildOrImprove
														)
														?.label?.replace(/\*\*/g, '')}
												</h5>
											)}

										{/* Question 13 PA */}
										{shouldDisplayPAQuestion(
											incidentForm?.whatToBuildOrImprove,
											'Q13'
										) && (
											<>
												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_constructionType,
													getLabelFromList(
														radioListConstructionType,
														incidentForm?.constructionType
													)
												)}
											</>
										)}

										{/* Question 14 PA */}
										{shouldDisplayPAQuestion(
											incidentForm?.whatToBuildOrImprove,
											'Q14'
										) && (
											<>
												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_constructionTypeOther,
													incidentForm?.constructionTypeOther
												)}
											</>
										)}

										{/* Question 15 PA */}
										{shouldDisplayPAQuestion(
											incidentForm?.whatToBuildOrImprove,
											'Q15'
										) && (
											<>
												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_foundationType,
													getLabelFromList(
														radioListFoundationType,
														incidentForm?.foundationType
													)
												)}
											</>
										)}

										{/* Question 16 PA */}
										{shouldDisplayPAQuestion(
											incidentForm?.whatToBuildOrImprove,
											'Q16'
										) && (
											<>
												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_placeOnTheTerrain,
													getLabelFromList(
														radioListPlaceOnTheTerrain,
														incidentForm?.placeOnTheTerrain
													)
												)}
											</>
										)}

										{incidentForm?.whatToBuildOrImprove &&
											incidentForm?.whatToBuildOrImprove?.toString() !=
												REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fifthOption &&
											(incidentForm?.buildingWidth !== undefined ||
												incidentForm?.buildingLength !== undefined ||
												incidentForm?.buildingHeight !== undefined ||
												incidentForm?.buildingSurfaceArea !== undefined) && (
												<h6 className={formUtils.classes.h6}>
													{
														pageAssets.request_form_buildingConstructionOrAdditionPermit_dimensions
													}
												</h6>
											)}

										{/* Question 17 PA */}
										{shouldDisplayPAQuestion(
											incidentForm?.whatToBuildOrImprove,
											'Q17'
										) &&
											incidentForm?.buildingWidth !== undefined && (
												<>
													{questionAnswer(
														pageAssets?.request_form_width,
														`${incidentForm?.buildingWidth} ${getLabelFromList(
															radioListMeasurementUnit,
															incidentForm?.buildingWidthUnit
														)}`
													)}
												</>
											)}

										{/* Question 18 PA */}
										{shouldDisplayPAQuestion(
											incidentForm?.whatToBuildOrImprove,
											'Q18'
										) &&
											incidentForm?.buildingLength !== undefined && (
												<>
													{questionAnswer(
														pageAssets?.request_form_length,
														`${incidentForm?.buildingLength} ${getLabelFromList(
															radioListMeasurementUnit,
															incidentForm?.buildingLengthUnit
														)}`
													)}
												</>
											)}

										{/* Question 19 PA */}
										{shouldDisplayPAQuestion(
											incidentForm?.whatToBuildOrImprove,
											'Q19'
										) &&
											incidentForm?.buildingHeight !== undefined && (
												<>
													{questionAnswer(
														pageAssets?.request_form_height,
														`${incidentForm?.buildingHeight} ${getLabelFromList(
															radioListMeasurementUnit,
															incidentForm?.buildingHeightUnit
														)}`
													)}
												</>
											)}

										{/* Question 20 PA */}
										{shouldDisplayPAQuestion(
											incidentForm?.whatToBuildOrImprove,
											'Q20'
										) &&
											incidentForm?.buildingSurfaceArea !== undefined && (
												<>
													{questionAnswer(
														pageAssets?.request_form_surfaceArea,
														`${
															incidentForm?.buildingSurfaceArea
														} ${getLabelFromList(
															radioListMeasurement2Unit,
															incidentForm?.buildingSurfaceAreaUnit
														)}`
													)}
												</>
											)}

										{incidentForm?.whatToBuildOrImprove &&
											![
												REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fifthOption,
												REQUEST_CONFIGS_KEYS.whatToBuildOrImprove.fourthOption
											].includes(
												incidentForm?.whatToBuildOrImprove?.toString()
											) &&
											(incidentForm?.buildingExternalCoveringMaterials ||
												incidentForm?.buildingRoofMaterials ||
												incidentForm?.buildingStructureMaterials) && (
												<h6 className={formUtils.classes.h6}>
													{
														pageAssets.request_form_buildingConstructionOrAdditionPermit_materials
													}
												</h6>
											)}

										{/* Question 21 PA */}
										{shouldDisplayPAQuestion(
											incidentForm?.whatToBuildOrImprove,
											'Q21'
										) && (
											<>
												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_buildingExternalCoveringMaterials,
													incidentForm?.buildingExternalCoveringMaterials
												)}
											</>
										)}

										{/* Question 22 PA */}
										{shouldDisplayPAQuestion(
											incidentForm?.whatToBuildOrImprove,
											'Q22'
										) && (
											<>
												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_buildingRoofMaterials,
													incidentForm?.buildingRoofMaterials
												)}
											</>
										)}

										{/* Question 23 PA */}
										{shouldDisplayPAQuestion(
											incidentForm?.whatToBuildOrImprove,
											'Q23'
										) && (
											<>
												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_buildingStructureMaterials,
													incidentForm?.buildingStructureMaterials
												)}
											</>
										)}
									</>
								)}
								<br />
							</Collapse>

							<Collapse
								id={5}
								buttonText={pageAssets?.request_form_information_on_the_work}
								key={`request_form_information_on_the_work`}
								isOpen={false}
							>
								<>
									{/* Question 24 */}
									<div className={pageUtils.classes.answerMargin}>
										<strong>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_isWorkDueToDisaster
											}
										</strong>
										<br />
										<span>
											{getLabelFromList(
												radioListYesNo,
												incidentForm?.isWorkDueToDisaster
											)}
										</span>
									</div>

									{incidentForm?.isWorkDueToDisaster?.toString() ==
										radioListYesNo[0].value && (
										<>
											<div className={pageUtils.classes.answerMargin}>
												<strong>
													{
														pageAssets?.request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision
													}
												</strong>
												<br />
												<span>{incidentForm?.workDueToDisasterPrecision}</span>
											</div>
										</>
									)}

									{/* Question 25 */}
									<div className={pageUtils.classes.answerMargin}>
										<strong>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_approximateCostOfWork
											}
										</strong>
										<br />
										<span>
											{formatNumberWithSpaces(
												incidentForm?.approximateCostOfWork?.toString()
											)}{' '}
											$
										</span>
									</div>

									{/* Question 26 */}
									{isNewConstruction() && (
										<div className={pageUtils.classes.answerMargin}>
											<strong>
												{
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_totalProjectValue
												}
											</strong>
											<br />
											<span>
												{formatNumberWithSpaces(
													incidentForm?.totalProjectValue?.toString()
												)}{' '}
												$
											</span>
										</div>
									)}

									{/* Question 27 */}
									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTreeCuttingRequired,
										getLabelFromList(
											radioListYesNo,
											incidentForm?.isTreeCuttingRequired
										)
									)}

									{/* Question 28 */}
									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ28,
										incidentForm?.additionalInfoQ28
									)}

									{/* Dates des travaux */}
									{(incidentForm?.workStartDate ||
										incidentForm?.workEndDate) && (
										<h5 className={pageUtils.classes.h5}>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_work_dates
											}
										</h5>
									)}

									{incidentForm?.workStartDate && (
										<div className={pageUtils.classes.answerMargin}>
											<strong>
												{
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_startDate
												}
											</strong>
											<br />
											<span>
												{pageUtils.getLocaleDate(incidentForm?.workStartDate!)}
											</span>
										</div>
									)}

									{incidentForm?.workEndDate && (
										<div className={pageUtils.classes.answerMargin}>
											<strong>
												{
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_endDate
												}
											</strong>
											<br />
											<span>
												{pageUtils.getLocaleDate(incidentForm?.workEndDate!)}
											</span>
										</div>
									)}

									<h5 className={pageUtils.classes.h5}>
										{
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_executing_work
										}
									</h5>
									{/* Question 31 */}
									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_whoWillBeInChargeOfWork,
										getLabelFromList(
											radioListWhoWillBeInChargeOfWork,
											incidentForm?.whoWillBeInChargeOfWork
										)
									)}

									{[
										radioListWhoWillBeInChargeOfWork[1].value,
										radioListWhoWillBeInChargeOfWork[2].value
									].includes(
										incidentForm?.whoWillBeInChargeOfWork?.toString()!
									) &&
										questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown,
											getLabelFromList(
												radioListYesNo,
												incidentForm?.entrepreneurUnknown1?.toString() ||
													String(false)
											)
										)}

									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork,
										incidentForm?.companyNameExecutingWork
									)}

									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNEQNumber,
										incidentForm?.companyNEQNumber
									)}

									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRBQNumber,
										incidentForm?.companyRBQNumber
									)}

									{(incidentForm?.representativeInfo?.organization_name ||
										incidentForm?.representativeInfo?.telephone ||
										incidentForm?.representativeInfo?.email) && (
										<h6 className={formUtils.classes.h6}>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
											}
										</h6>
									)}

									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName,
										incidentForm?.representativeInfo?.organization_name
									)}

									{questionAnswer(
										pageAssets?.label_telephone,
										incidentForm?.representativeInfo?.telephone
									)}

									{questionAnswer(
										pageAssets?.label_email,
										incidentForm?.representativeInfo?.email
									)}

									{(incidentForm?.contactDetailsInfo?.address ||
										incidentForm?.contactDetailsInfo?.city ||
										(incidentForm?.contactDetailsInfo?.postal_code &&
											incidentForm?.contactDetailsInfo?.postal_code !==
												'n/d')) && (
										<h6 className={formUtils.classes.h6}>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
											}
										</h6>
									)}

									{questionAnswer(
										pageAssets?.label_address,
										incidentForm?.contactDetailsInfo?.address
									)}

									{questionAnswer(
										pageAssets?.label_city,
										incidentForm?.contactDetailsInfo?.city
									)}

									{incidentForm?.contactDetailsInfo?.postal_code !== 'n/d' &&
										questionAnswer(
											pageAssets?.label_postalCode,
											incidentForm?.contactDetailsInfo?.postal_code
										)}
								</>
								<br />
							</Collapse>

							<Collapse
								id={6}
								buttonText={pageAssets?.request_form_branching_aqueduc_egouts}
								key={`request_form_branching_aqueduc_egouts`}
								isOpen={false}
							>
								{questionAnswer(
									pageAssets?.request_form_buildingConstructionOrAdditionPermit_isWaterAndSewerConnectionRequired,
									getLabelFromList(
										radioListYesNo,
										incidentForm?.isWaterAndSewerConnectionRequired?.toString() ||
											String(false)
									)
								)}

								{(incidentForm?.connectedToAqueduct ||
									incidentForm?.connectedToRainSewer ||
									incidentForm?.connectedToCombinedSewer ||
									incidentForm?.connectedToDNA) && (
									<div className={pageUtils.classes.answerMargin}>
										<strong>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits
											}
										</strong>
										<br />
										<span className="no-margin">
											<>
												{/* {getLabelsFromString(
													radioListIsPropertyConnectedToMunicipalConduits,
													incidentForm?.isPropertyConnectedToMunicipalConduits
												).map((answerLabel, index) => {
													return (
														<p
															key={`${index}-isPropertyConnectedToMunicipalConduits`}
														>
															{answerLabel}
														</p>
													)
												})} */}
												{/* connectedToAqueduct */}
												{incidentForm?.connectedToAqueduct && (
													<p>
														{
															radioListIsPropertyConnectedToMunicipalConduits[0]
																.label
														}
													</p>
												)}
												{/* connectedToRainSewer */}
												{incidentForm?.connectedToRainSewer && (
													<p>
														{
															radioListIsPropertyConnectedToMunicipalConduits[1]
																.label
														}
													</p>
												)}
												{/* connectedToCombinedSewer */}
												{incidentForm?.connectedToCombinedSewer && (
													<p>
														{
															radioListIsPropertyConnectedToMunicipalConduits[2]
																.label
														}
													</p>
												)}
												{/* connectedToDNA */}
												{incidentForm?.connectedToDNA && (
													<p>
														{
															radioListIsPropertyConnectedToMunicipalConduits[3]
																.label
														}
													</p>
												)}
											</>
										</span>
									</div>
								)}

								{questionAnswer(
									pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ42,
									incidentForm?.additionalInfoQ42
								)}

								{(incidentForm?.constructionType1 ||
									incidentForm?.constructionType2 ||
									incidentForm?.constructionType3 ||
									incidentForm?.constructionType4 ||
									incidentForm?.constructionType5 ||
									incidentForm?.constructionType6 ||
									incidentForm?.constructionType7) && (
									<div className={pageUtils.classes.answerMargin}>
										<strong>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork
											}
										</strong>
										<br />
										<span className="no-margin">
											<>
												{/* {getLabelsFromString(
													listPlannedWork,
													incidentForm?.plannedWork
												).map((answerLabel, index) => {
													return (
														<p key={`${index}-plannedWork`}>{answerLabel}</p>
													)
												})} */}

												{/* constructionType1 */}
												{incidentForm?.constructionType1 && (
													<p>{listPlannedWork[0].label}</p>
												)}

												{/* constructionType2 */}
												{incidentForm?.constructionType2 && (
													<p>{listPlannedWork[1].label}</p>
												)}

												{/* constructionType3 */}
												{incidentForm?.constructionType3 && (
													<p>{listPlannedWork[2].label}</p>
												)}

												{/* constructionType4 */}
												{incidentForm?.constructionType4 && (
													<p>{listPlannedWork[3].label}</p>
												)}

												{/* constructionType5 */}
												{incidentForm?.constructionType5 && (
													<p>{listPlannedWork[4].label}</p>
												)}

												{/* constructionType6 */}
												{incidentForm?.constructionType6 && (
													<p>{listPlannedWork[5].label}</p>
												)}

												{/* constructionType7 */}
												{incidentForm?.constructionType7 && (
													<p>{listPlannedWork[6].label}</p>
												)}
											</>
										</span>
									</div>
								)}

								{questionAnswer(
									pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ44,
									incidentForm?.additionalInfoQ44
								)}

								{(incidentForm?.constructionType1 ||
									incidentForm?.constructionType2 ||
									incidentForm?.constructionType3 ||
									incidentForm?.constructionType6 ||
									incidentForm?.constructionType7) && (
									<>
										<h5 className={pageUtils.classes.h5}>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_branchement_aqueduc_egouts_ou_ingenieur_civil
											}
										</h5>

										{questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown,
											getLabelFromList(
												radioListYesNo,
												incidentForm?.entrepreneurUnknown?.toString() ||
													String(false)
											)
										)}

										{questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork,
											incidentForm?.entrepreneurName
										)}

										{questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRBQNumber,
											incidentForm?.entrepreneurRBQNumber
										)}

										{(incidentForm?.representativeInfo2?.organization_name ||
											incidentForm?.representativeInfo2?.telephone ||
											incidentForm?.representativeInfo2?.email) && (
											<h6 className={formUtils.classes.h6}>
												{
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
												}
											</h6>
										)}

										{questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName,
											incidentForm?.representativeInfo2?.organization_name
										)}

										{questionAnswer(
											pageAssets?.label_telephone,
											incidentForm?.representativeInfo2?.telephone
										)}

										{questionAnswer(
											pageAssets?.label_email,
											incidentForm?.representativeInfo2?.email
										)}

										{(incidentForm?.contactDetailsInfo2?.address ||
											incidentForm?.contactDetailsInfo2?.city ||
											(incidentForm?.contactDetailsInfo2?.postal_code &&
												incidentForm?.contactDetailsInfo2?.postal_code !==
													'n/d')) && (
											<h6 className={formUtils.classes.h6}>
												{
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
												}
											</h6>
										)}

										{questionAnswer(
											pageAssets?.label_address,
											incidentForm?.contactDetailsInfo2?.address
										)}

										{questionAnswer(
											pageAssets?.label_city,
											incidentForm?.contactDetailsInfo2?.city
										)}

										{incidentForm?.contactDetailsInfo2?.postal_code !== 'n/d' &&
											questionAnswer(
												pageAssets?.label_postalCode,
												incidentForm?.contactDetailsInfo2?.postal_code
											)}
									</>
								)}

								<br />
							</Collapse>
						</>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default BuildingConstructionOrAdditionPermitFormDetails
