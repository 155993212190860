import React, { FC, useState } from 'react'
import * as pageUtils from './__details.utils'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { RadioList, SelectList } from '@utils/request'
import { formatStrapiText } from '@utils/methods'
import TableDetailsModal from '../modals/tableDetailsModal'
import Text from '@components/ui/text'

const WateringPermitFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const getLabelFromList = (list: SelectList[], value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	return (
		<>
			{/* details 1 */}
			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_watering_permit}
			</h4>
		</>
	)
}

export default WateringPermitFormDetails
