import React, { FC, useState } from 'react'
import * as pageUtils from './__details.utils'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { CheckboxList, RadioList, SelectList } from '@utils/request'
import Collapse from '@components/ui/collapse'
import Modal from '@components/ui/modal'
import * as formUtils from '@components/requestForms/__forms.utils'
import { joinClasses } from '@utils/styles'
import { formatNumberWithSpaces } from '@components/requestForms/__forms.utils'

const BuildingDemolitionOrMovingPermitFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const radioListRequestAs: RadioList[] = [
		{
			label: pageAssets?.request_form_individual,
			value: REQUEST_CONFIGS_KEYS.requestAs.individual,
			key: 'request_form_individual'
		},
		{
			label: pageAssets?.request_form_entreprise,
			value: REQUEST_CONFIGS_KEYS.requestAs.entreprise,
			key: 'request_form_entreprise'
		}, //Entrepreneur or manager
		{
			label: pageAssets?.request_form_entrepreneur_or_manager,
			value: REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager,
			key: 'request_form_entrepreneur_or_manager'
		}
	]

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const radioListWhatToDo: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_request_demolition,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoBuildingDemolitionOrMovingPermit
					.request_demolition,
			key: 'request_form_buildingDemolitionOrMovingPermit_request_demolition',
			extraText:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_request_demolition_extra_text
		},
		{
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_request_moving,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoBuildingDemolitionOrMovingPermit
					.request_moving,
			key: 'request_form_buildingDemolitionOrMovingPermit_request_moving',
			extraText:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_request_moving_extra_text
		}
	]

	const radioListIsTheNewBuilding: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option1,
			value: REQUEST_CONFIGS_KEYS.isTheNewBuilding.firstOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option1'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option2,
			value: REQUEST_CONFIGS_KEYS.isTheNewBuilding.secondOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option2'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option3,
			value: REQUEST_CONFIGS_KEYS.isTheNewBuilding.thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option3'
		}
	]

	const radioListMainUseOfExistingBuilding: RadioList[] =
		formUtils.radioListMainUseOfExistingBuilding(pageAssets)

	const radioListStructureType: RadioList[] =
		formUtils.radioListStructureType(pageAssets)

	const radioListWhoWillBeInChargeOfWork: RadioList[] =
		formUtils.radioListWhoWillBeInChargeOfWork(pageAssets)

	const radioListIsPropertyConnectedToMunicipalConduits: CheckboxList[] =
		formUtils.radioListIsPropertyConnectedToMunicipalConduits(pageAssets)

	const listPlannedWork: CheckboxList[] = formUtils.listPlannedWork(pageAssets)

	const questionAnswer = (question: string, answer?: string) => {
		if (answer != undefined) {
			return (
				<>
					<div className={pageUtils.classes.answerMargin}>
						<strong>{question}</strong>
						<br />
						<span>{answer}</span>
					</div>
				</>
			)
		}
	}

	const getLabelFromList = (list: SelectList[], value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	const getLabelsFromString = (list, answer?: string): string[] => {
		return list
			.filter((element) => answer?.includes(element.value))
			.map((item) => item.label)
	}

	const { incidentForm } = request

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)


	const isDemolition = () =>
		incidentForm?.whatToDo?.toString() ==
		REQUEST_CONFIGS_KEYS.whatToDoBuildingDemolitionOrMovingPermit
			.request_demolition

	const isMoving = () =>
		incidentForm?.whatToDo?.toString() ==
		REQUEST_CONFIGS_KEYS.whatToDoBuildingDemolitionOrMovingPermit.request_moving

	const radioListTypeOfBuildingToDemolish: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_typeOfBuildingToDemolish_option1,
			value: REQUEST_CONFIGS_KEYS.typeOfBuildingToDemolish.firstOption,
			key: 'request_form_buildingDemolitionOrMovingPermit_typeOfBuildingToDemolish_option1'
		},
		{
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_typeOfBuildingToDemolish_option2,
			value: REQUEST_CONFIGS_KEYS.typeOfBuildingToDemolish.secondOption,
			key: 'request_form_buildingDemolitionOrMovingPermit_typeOfBuildingToDemolish_option2'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_foundationType_option4,
			value: REQUEST_CONFIGS_KEYS.typeOfBuildingToDemolish.thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_foundationType_option4'
		}
	]

	const radioListDemolitionType: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_demolitionType_option1,
			value: REQUEST_CONFIGS_KEYS.demolitionType.firstOption,
			key: 'request_form_buildingDemolitionOrMovingPermit_demolitionType_option1'
		},
		{
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_demolitionType_option2,
			value: REQUEST_CONFIGS_KEYS.demolitionType.secondOption,
			key: 'request_form_buildingDemolitionOrMovingPermit_demolitionType_option2'
		}
	]

	return (
		<>
			{/* Question 1 */}
			<div>
				<strong>{pageAssets.request_form_what_would_you_like_to_do}</strong>
				<br />
				<span>
					{getLabelFromList(radioListWhatToDo, incidentForm?.whatToDo)?.replace(
						/\*\*/g,
						''
					)}
				</span>
			</div>

			<p style={{ marginBottom: '20px' }}>
				<span
					onClick={() => {
						setIsModalOpen(true)
					}}
					className={pageUtils.classes.viewDetailsSpan}
				>
					<b>{pageAssets.request_form_show_details}</b>
				</span>
			</p>

			<Modal
				// cancelButtonText={pageAssets?.close_label}
				primaryButtonText={pageAssets?.close_label}
				isOpen={isModalOpen}
				onCancelBtn={() => {
					setIsModalOpen(false)
				}}
				onPrimaryButton={() => {
					setIsModalOpen(false)
				}}
				onSetIsOpen={() => {}}
				alignBtn={'left'}
				title={pageAssets?.request_form_request_details}
				showExitBtn
			>
				<div>
					<div
						style={{ marginTop: '-20px' }}
						className={joinClasses([
							pageUtils.classes.collapse,
							pageUtils.classes.summarySubTitles
						])}
					>
						<>
							<Collapse
								id={1}
								buttonText={pageAssets?.request_form_type_de_demandes}
								key={`request_form_type_de_demandes`}
								isOpen={false}
							>
								{/* Question 1 */}
								{questionAnswer(
									pageAssets?.request_form_what_would_you_like_to_do,
									getLabelFromList(
										radioListWhatToDo,
										incidentForm?.whatToDo
									)?.replace(/\*\*/g, '')
								)}

								{/* Question 2 */}
								{questionAnswer(
									pageAssets?.request_form_request_as,
									getLabelFromList(radioListRequestAs, incidentForm?.requestAs)
								)}

								{[
									REQUEST_CONFIGS_KEYS.requestAs.entreprise,
									REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager
								].includes(incidentForm?.requestAs?.toString() || '') && (
									<>
										{questionAnswer(
											pageAssets?.request_form_annualRegistrationCertificateForContractors_companyName_label,
											incidentForm?.companyName
										)}
										{questionAnswer(
											pageAssets?.request_form_swimmingPoolSpaPermit_representativeName_label,
											incidentForm?.representativeName
										)}
									</>
								)}

								{isMoving() && (
									<>
										{/* Question 3: Is Subject to PIIA Approval */}
										{questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_label,
											getLabelFromList(
												radioListYesNo,
												incidentForm?.isSubjectToPiiaApproval
											)
										)}

										{/* Question 4: Has Been Subject to Approval */}
										{incidentForm?.hasBeenSubjectToApproval?.toString() ==
											radioListYesNo[0].value && (
											<>
												{questionAnswer(
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_hasBeenSubjectToApproval_label,
													getLabelFromList(
														radioListYesNo,
														incidentForm?.hasBeenSubjectToApproval
													)
												)}
												{incidentForm?.hasBeenSubjectToApproval?.toString() ==
													radioListYesNo[0].value && (
													<>
														{questionAnswer(
															pageAssets?.request_form_buildingConstructionOrAdditionPermit_iaNumber_label,
															incidentForm?.iaNumber
														)}

														{questionAnswer(
															pageAssets?.request_form_buildingConstructionOrAdditionPermit_ecResolutionNumber_label,
															incidentForm?.ecResolutionNumber
														)}
													</>
												)}
											</>
										)}
									</>
								)}

								<br />
							</Collapse>

							<Collapse
								id={2}
								buttonText={pageAssets?.request_form_request_location}
								key={`request_form_request_location`}
								isOpen={false}
							>
								{questionAnswer(
									pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSameLocation,
									getLabelFromList(
										radioListYesNo,
										incidentForm?.buildingAddressSameHasApplicant
									)
								)}

								{incidentForm?.buildingAddressSameHasApplicant?.toString() ==
									radioListYesNo[1].value && (
									<>
										{questionAnswer(
											pageAssets?.request_form_location,
											incidentForm?.addressField
										)}
										{questionAnswer(
											pageAssets?.label_cadastral_number,
											incidentForm?.addressLotNumber?.toString()
										)}

										{incidentForm?.addressVoiePublic && (
											<div>
												<strong>
													{
														pageAssets?.request_form_addressVoiePublicSection_public_road
													}
												</strong>
												<br />
												<span>
													{incidentForm?.addressCivicNumber
														? incidentForm?.addressCivicNumber + ', '
														: ''}
													{incidentForm?.addressVoiePublic}
												</span>
											</div>
										)}
										{incidentForm?.addressVoiePublic2 && (
											<div>
												<span>
													{incidentForm?.addressCivicNumber2
														? incidentForm?.addressCivicNumber2 + ', '
														: ''}
													{incidentForm?.addressVoiePublic2}
												</span>
											</div>
										)}

										{incidentForm?.addressVoiePublic3 && (
											<div>
												<span>
													{incidentForm?.addressCivicNumber3
														? incidentForm?.addressCivicNumber3 + ', '
														: ''}
													{incidentForm?.addressVoiePublic3}
												</span>
											</div>
										)}

										{incidentForm?.addressAdditionalInfos && (
											<div>
												<span>{incidentForm?.addressAdditionalInfos}</span>
											</div>
										)}
										<div className={pageUtils.classes.answerMargin}></div>
									</>
								)}

								{/* Question 9 */}
								{questionAnswer(
									pageAssets?.request_form_swimmingPoolSpaPermit_isLocationOwned,
									getLabelFromList(
										radioListYesNo,
										incidentForm?.isLocationOwned
									)
								)}

								{/* ****** Owner inputs ****** */}
								{incidentForm?.isLocationOwned?.toString() ==
									radioListYesNo[1].value && (
									<>
										{questionAnswer(
											pageAssets?.label_firstName,
											incidentForm?.buildingOwnerInfo?.first_name
										)}
										{questionAnswer(
											pageAssets?.label_lastName,
											incidentForm?.buildingOwnerInfo?.last_name
										)}
										{questionAnswer(
											pageAssets?.label_email,
											incidentForm?.buildingOwnerInfo?.email
										)}
										{questionAnswer(
											pageAssets?.label_telephone,
											incidentForm?.buildingOwnerInfo?.telephone
										)}
									</>
								)}

								<br />
							</Collapse>

							<Collapse
								id={3}
								buttonText={pageAssets?.request_form_type_of_use}
								key={`request_form_type_of_use`}
								isOpen={false}
							>
								<>
									{/* Question 10 */}
									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding,
										getLabelFromList(
											radioListMainUseOfExistingBuilding,
											incidentForm?.mainUseOfExistingBuilding
										)
									)}

									{questionAnswer(
										pageAssets?.request_form_buildingDemolitionOrMovingPermit_structureType,
										getLabelFromList(
											radioListStructureType,
											incidentForm?.existingMainBuildingStructureType
										)
									)}
									{/* Question 11 */}
									{questionAnswer(
										pageAssets?.request_form_buildingDemolitionOrMovingPermit_isTheNewBuilding,
										getLabelFromList(
											radioListIsTheNewBuilding,
											incidentForm?.existingMainBuildingIs
										)
									)}
									{/* Question 12 */}
									{questionAnswer(
										pageAssets?.request_form_buildingDemolitionOrMovingPermit_isTheNewBuildingPartOfAProperty,
										getLabelFromList(
											radioListYesNo,
											incidentForm?.existingMainBuildingIsShared
										)
									)}
								</>

								<br />
							</Collapse>

							<Collapse
								id={4}
								buttonText={
									isDemolition()
										? pageAssets?.request_form_building_demolition
										: pageAssets?.request_form_building_moving
								}
								key={`request_form_building_informations`}
								isOpen={false}
							>
								{isDemolition() && (
									<>
										{questionAnswer(
											pageAssets?.request_form_buildingDemolitionOrMovingPermit_typeOfBuildingToDemolish,
											getLabelFromList(
												radioListTypeOfBuildingToDemolish,
												incidentForm?.typeOfBuildingToDemolish
											)
										)}

										{incidentForm?.typeOfBuildingToDemolish?.toString() ==
											radioListTypeOfBuildingToDemolish[2].value &&
											questionAnswer(
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision,
												incidentForm?.typeOfBuildingToDemolishPrecision
											)}

										{questionAnswer(
											pageAssets?.request_form_buildingDemolitionOrMovingPermit_demolitionType,
											getLabelFromList(
												radioListDemolitionType,
												incidentForm?.demolitionType
											)
										)}
									</>
								)}

								{isMoving() && <></>}
								<br />
							</Collapse>

							<Collapse
								id={5}
								buttonText={pageAssets?.request_form_information_on_the_work}
								key={`request_form_information_on_the_work`}
								isOpen={false}
							>
								<>
									{/* Question 24 */}
									<div className={pageUtils.classes.answerMargin}>
										<strong>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_isWorkDueToDisaster
											}
										</strong>
										<br />
										<span>
											{getLabelFromList(
												radioListYesNo,
												incidentForm?.isWorkDueToDisaster
											)}
										</span>
									</div>

									{incidentForm?.isWorkDueToDisaster?.toString() ==
										radioListYesNo[0].value && (
										<>
											<div className={pageUtils.classes.answerMargin}>
												<strong>
													{
														pageAssets?.request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision
													}
												</strong>
												<br />
												<span>{incidentForm?.workDueToDisasterPrecision}</span>
											</div>
										</>
									)}

									{/* Question 25 */}
									<div className={pageUtils.classes.answerMargin}>
										<strong>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_approximateCostOfWork
											}
										</strong>
										<br />
										<span>
											{formatNumberWithSpaces(
												incidentForm?.approximateCostOfWork?.toString()
											)}{' '}
											$
										</span>
									</div>

									{/* Question 27 */}
									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTreeCuttingRequired,
										getLabelFromList(
											radioListYesNo,
											incidentForm?.isTreeCuttingRequired
										)
									)}

									{/* Question 28 */}
									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ28,
										incidentForm?.additionalInfoQ28
									)}

									{/* Dates des travaux */}
									{(incidentForm?.workStartDate ||
										incidentForm?.workEndDate) && (
										<h5 className={pageUtils.classes.h5}>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_work_dates
											}
										</h5>
									)}

									{incidentForm?.workStartDate && (
										<div className={pageUtils.classes.answerMargin}>
											<strong>
												{
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_startDate
												}
											</strong>
											<br />
											<span>
												{pageUtils.getLocaleDate(incidentForm?.workStartDate!)}
											</span>
										</div>
									)}

									{incidentForm?.workEndDate && (
										<div className={pageUtils.classes.answerMargin}>
											<strong>
												{
													pageAssets?.request_form_buildingConstructionOrAdditionPermit_endDate
												}
											</strong>
											<br />
											<span>
												{pageUtils.getLocaleDate(incidentForm?.workEndDate!)}
											</span>
										</div>
									)}

									<h5 className={pageUtils.classes.h5}>
										{
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_executing_work
										}
									</h5>
									{/* Question 31 */}
									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_whoWillBeInChargeOfWork,
										getLabelFromList(
											radioListWhoWillBeInChargeOfWork,
											incidentForm?.whoWillBeInChargeOfWork
										)
									)}

									{[
										radioListWhoWillBeInChargeOfWork[1].value,
										radioListWhoWillBeInChargeOfWork[2].value
									].includes(
										incidentForm?.whoWillBeInChargeOfWork?.toString()!
									) &&
										questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown,
											getLabelFromList(
												radioListYesNo,
												incidentForm?.entrepreneurUnknown1?.toString() ||
													String(false)
											)
										)}

									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork,
										incidentForm?.companyNameExecutingWork
									)}

									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNEQNumber,
										incidentForm?.companyNEQNumber
									)}

									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRBQNumber,
										incidentForm?.companyRBQNumber
									)}

									{(incidentForm?.representativeInfo?.organization_name ||
										incidentForm?.representativeInfo?.telephone ||
										incidentForm?.representativeInfo?.email) && (
										<h6 className={formUtils.classes.h6}>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
											}
										</h6>
									)}

									{questionAnswer(
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName,
										incidentForm?.representativeInfo?.organization_name
									)}

									{questionAnswer(
										pageAssets?.label_telephone,
										incidentForm?.representativeInfo?.telephone
									)}

									{questionAnswer(
										pageAssets?.label_email,
										incidentForm?.representativeInfo?.email
									)}

									{(incidentForm?.contactDetailsInfo?.address ||
										incidentForm?.contactDetailsInfo?.city ||
										(incidentForm?.contactDetailsInfo?.postal_code &&
											incidentForm?.contactDetailsInfo?.postal_code !==
												'n/d')) && (
										<h6 className={formUtils.classes.h6}>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
											}
										</h6>
									)}

									{questionAnswer(
										pageAssets?.label_address,
										incidentForm?.contactDetailsInfo?.address
									)}

									{questionAnswer(
										pageAssets?.label_city,
										incidentForm?.contactDetailsInfo?.city
									)}

									{incidentForm?.contactDetailsInfo?.postal_code !== 'n/d' &&
										questionAnswer(
											pageAssets?.label_postalCode,
											incidentForm?.contactDetailsInfo?.postal_code
										)}
								</>
								<br />
							</Collapse>

							<Collapse
								id={6}
								buttonText={pageAssets?.request_form_branching_aqueduc_egouts}
								key={`request_form_branching_aqueduc_egouts`}
								isOpen={false}
							>
								{questionAnswer(
									pageAssets?.request_form_buildingConstructionOrAdditionPermit_isWaterAndSewerConnectionRequired,
									getLabelFromList(
										radioListYesNo,
										incidentForm?.isWaterAndSewerConnectionRequired?.toString() ||
											String(false)
									)
								)}

								{incidentForm?.isWaterAndSewerConnectionRequired?.toString() ==
									'true' && (
									<>
										{(incidentForm?.connectedToAqueduct ||
											incidentForm?.connectedToRainSewer ||
											incidentForm?.connectedToCombinedSewer ||
											incidentForm?.connectedToDNA) && (
											<div className={pageUtils.classes.answerMargin}>
												<strong>
													{
														pageAssets?.request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits
													}
												</strong>
												<br />
												<span className="no-margin">
													<>
														{/* {getLabelsFromString(
													radioListIsPropertyConnectedToMunicipalConduits,
													incidentForm?.isPropertyConnectedToMunicipalConduits
												).map((answerLabel, index) => {
													return (
														<p
															key={`${index}-isPropertyConnectedToMunicipalConduits`}
														>
															{answerLabel}
														</p>
													)
												})} */}
														{/* connectedToAqueduct */}
														{incidentForm?.connectedToAqueduct && (
															<p>
																{
																	radioListIsPropertyConnectedToMunicipalConduits[0]
																		.label
																}
															</p>
														)}
														{/* connectedToRainSewer */}
														{incidentForm?.connectedToRainSewer && (
															<p>
																{
																	radioListIsPropertyConnectedToMunicipalConduits[1]
																		.label
																}
															</p>
														)}
														{/* connectedToCombinedSewer */}
														{incidentForm?.connectedToCombinedSewer && (
															<p>
																{
																	radioListIsPropertyConnectedToMunicipalConduits[2]
																		.label
																}
															</p>
														)}
														{/* connectedToDNA */}
														{incidentForm?.connectedToDNA && (
															<p>
																{
																	radioListIsPropertyConnectedToMunicipalConduits[3]
																		.label
																}
															</p>
														)}
													</>
												</span>
											</div>
										)}

										{questionAnswer(
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ42,
											incidentForm?.additionalInfoQ42
										)}
									</>
								)}

								{incidentForm?.isWaterAndSewerConnectionRequired?.toString() ==
									'true' &&
									isMoving() && (
										<>
											{(incidentForm?.constructionType1 ||
												incidentForm?.constructionType2 ||
												incidentForm?.constructionType3 ||
												incidentForm?.constructionType4 ||
												incidentForm?.constructionType5 ||
												incidentForm?.constructionType6 ||
												incidentForm?.constructionType7) && (
												<div className={pageUtils.classes.answerMargin}>
													<strong>
														{
															pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork
														}
													</strong>
													<br />
													<span className="no-margin">
														<>
															{/* {getLabelsFromString(
													listPlannedWork,
													incidentForm?.plannedWork
												).map((answerLabel, index) => {
													return (
														<p key={`${index}-plannedWork`}>{answerLabel}</p>
													)
												})} */}

															{/* constructionType1 */}
															{incidentForm?.constructionType1 && (
																<p>{listPlannedWork[0].label}</p>
															)}

															{/* constructionType2 */}
															{incidentForm?.constructionType2 && (
																<p>{listPlannedWork[1].label}</p>
															)}

															{/* constructionType3 */}
															{incidentForm?.constructionType3 && (
																<p>{listPlannedWork[2].label}</p>
															)}

															{/* constructionType4 */}
															{incidentForm?.constructionType4 && (
																<p>{listPlannedWork[3].label}</p>
															)}

															{/* constructionType5 */}
															{incidentForm?.constructionType5 && (
																<p>{listPlannedWork[4].label}</p>
															)}

															{/* constructionType6 */}
															{incidentForm?.constructionType6 && (
																<p>{listPlannedWork[5].label}</p>
															)}

															{/* constructionType7 */}
															{incidentForm?.constructionType7 && (
																<p>{listPlannedWork[6].label}</p>
															)}
														</>
													</span>
												</div>
											)}

											{questionAnswer(
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ44,
												incidentForm?.additionalInfoQ44
											)}
										</>
									)}

								<br />
							</Collapse>
						</>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default BuildingDemolitionOrMovingPermitFormDetails
